@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500&family=Nunito:ital,wght@1,200;1,300&display=swap");

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "DM Sans", sans-serif;
}

body {
  font-family: "DM Sans", sans-serif;
}

/* Seller Panel */

.bg-img {
  background: url("../src/assets/images/linear-bg.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.bg {
  height: 100vh;
  background-size: cover;
}
.head-black {
  color: black;
  font-size: 30px;
  font-weight: 700;
}
.head-muted {
  color: #4b4b4b;
  font-size: 18px;
  font-weight: 400;
}
.form-label {
  color: #333f51;
  font-weight: 500;
}
.fc-size .form-control {
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
}
.fc-size .form-control:focus {
  box-shadow: none;
}
.rem-color {
  font-weight: 500;
  color: black;
  font-size: 13px;
}
.forgot-color {
  color: #fe8d35;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
}
.btn-linear {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0px 23px 24px -10px rgba(255, 123, 57, 0.37);
  border-radius: 11px;
}
.btn-linear:hover {
  background: white;
  border: 1px solid #ff8126;
  color: #ff8126;
}
.btn-google {
  padding-top: 12px;
  padding-bottom: 12px;
  background: white;
  border: 1px solid #eef0f3;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 11px;
  font-weight: 500;
  font-size: 16px;
}
.btn-google:hover {
  border: 1px solid #ff8126;
  background: white;
  color: #ff8126;
}
.dont-color {
  color: black;
  font-size: 14px;
  font-weight: 700;
}
.new-account {
  color: rgba(255, 97, 8, 1);
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
::placeholder {
  color: #2a3342;
  font-size: 16px;
  font-weight: 400;
}
.otp-span {
  font-weight: 700;
  color: #4b4b4b;
}
.inputfield {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.input {
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 11px;
  height: 2.8em;
  width: 2.8em;
  outline: none;
  text-align: center;
  font-size: 1.5em;
  background-color: #ffffff;
  outline: none;
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*body::-webkit-scrollbar {
 display: none;
}*/
.add-pic {
  font-weight: 400;
  font-size: 15px;
  color: black;
  padding-top: 8px;
}
.btn-stripe {
  padding-top: 12px;
  padding-bottom: 12px;
  background: white;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 11px;
  font-weight: 600;
  color: black;
  font-size: 15px;
  border: 1px solid #959595;
}
.btn-stripe:hover {
  border: 1px solid #ff8126;
  background: white;
  color: #ff8126;
}
.btn-stripe.active {
  border: 1px solid #31bb92;
  padding-top: 12px;
  padding-bottom: 12px;
  background: white;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 11px;
  font-weight: 600;
  color: black;
  font-size: 15px;
}

/* Business Panel */

.subscription-bg {
  background: url("../src/assets/images/linear-bg.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.subscription-card {
  background: rgb(255 183 119);
  border-radius: 25px;
  border: none;
  color: black;
}
.subscription-card h6 {
  float: right;
  color: white;
  font-weight: bold;
  font-size: 11px;
  background: rgba(255, 129, 38, 1);
  border-radius: 13.5px;
  padding: 5px 10px;
}
.subscription-card .clear {
  clear: both;
}
.subscription-card h2 {
  font-weight: bold;
  color: black;
  font-size: 40px;
}
.subscription-card .small-month {
  font-size: 20px;
  font-weight: 500;
}
.subscription-card .check-bg {
  background-color: #aaffdc4a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  display: inline-block;
}
.subscription-card .check-bg i {
  font-size: 14px;
  color: #00b168;
}
.subscription-card .btn-plan {
  background: #ff6108;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Media Queries */

@media (min-width: 1400px) and (max-width: 1500px) {
  .bg.bg-media {
    height: 100%;
  }
  .head-black {
    color: black;
    font-size: 25px;
    font-weight: 700;
  }
  .bg-img {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1210px) and (max-width: 1300px) {
  .px-media-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .bg.bg-media {
    height: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .bg.bg-media {
    height: 100%;
  }
  .bg-img {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bg {
    height: 100%;
  }
  .head-black {
    color: black;
    font-size: 25px;
    font-weight: 700;
  }
  .bg-img {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .mobile-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .bg {
    height: 50vh;
    object-fit: cover;
    display: none;
  }
  .px-media-0 {
    padding-top: 20px;
  }
  .subscription-card {
    margin-bottom: 20px;
  }
}

/* Home Dashboard Page */

.fw-bold-1 {
  font-size: 20px;
  font-weight: 400;
  color: #000;
}

.fw-bold-2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}

.dashboard-table tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 700;
  color: #111827;
  border: none;
}

.dashboard-table > tbody > tr:nth-of-type(odd) > * {
  background: rgba(255, 255, 252, 1) !important;
  opacity: 1 !important;
}

.dashboard-table > tbody > tr:nth-of-type(even) > * {
  background: #f9fafb;
}

.show.menu-toggle .deznav .metismenu > li {
  margin-left: 0px;
  margin-right: 0px;
}

@media (max-width: 767px) {
  .dashboard-table {
    white-space: nowrap;
  }
}

/* End Home Dashboard Page */

/* Profile Page */
.btn-password {
  border: 1px solid #eef0f3;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.08);
  color: black;
  font-size: 16px;
  padding: 15px 50px;
  font-weight: 700;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 12px;
  z-index: 1;
  bottom: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input + label:after {
  content: "";
  background: url("../src/assets/images/edit.svg");
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-text label {
  color: #333f51;
  font-size: 16px;
  font-weight: 500;
}

.text-cp {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

#change-password h1 {
  color: black;
  font-weight: 22px;
  font-weight: 700;
}

#change-password label {
  color: #333f51;
  font-size: 16px;
  font-weight: 500;
}

#change-password .form-control {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #dddddd;
}

#change-password .btn-update {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(255, 123, 57, 0.37);
  border-radius: 11px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
  font-size: 16px;
}

#change-password .btn-close:focus {
  box-shadow: none;
}
/* End Profile Page */

/* Add Business Page */
.business-text h2 {
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.business-text p {
  color: #71717a;
  font-size: 14px;
  font-weight: 400;
}

.btn-business {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  border-radius: 11px;
  color: white;
  font-size: 16px;
  padding: 15px 50px;
  font-weight: 700;
}

.business-table tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 700;
  color: #111827;
  border: none;
}

.business-table > tbody > tr:nth-of-type(odd) > * {
  background: rgba(255, 255, 252, 1) !important;
  opacity: 1 !important;
}

.business-table > tbody > tr:nth-of-type(even) > * {
  background: #f9fafb;
}

.business-text tr th {
  color: #6b7280;
  font-size: 14px;
  background: #f9fafb;
  padding-top: 15px;
  padding-bottom: 15px;
}

.badge-orange {
  background: rgba(255, 97, 8, 0.15);
  border-radius: 4px;
  color: #ff6108;
  font-size: 13px;
  padding: 10px 20px;
  font-weight: 400;
}

.badge-red {
  background: rgba(234, 67, 53, 0.15);
  border-radius: 4px;
  color: #ea4335;
  font-size: 13px;
  padding: 10px 20px;
  font-weight: 400;
}

.badge-green {
  background: rgba(61, 185, 162, 0.15);
  border-radius: 4px;
  color: #3db9a2;
  font-size: 13px;
  padding: 10px 20px;
  font-weight: 400;
}

#add-business h1 {
  color: black;
  font-weight: 22px;
  font-weight: 700;
}

#add-business label {
  color: #333f51;
  font-size: 16px;
  font-weight: 500;
}

#add-business .form-control {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #dddddd;
}

#add-business .btn-add {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(255, 123, 57, 0.37);
  border-radius: 11px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  color: white;
  font-size: 16px;
}

#add-business .btn-close:focus {
  box-shadow: none;
}

@media (max-width: 767px) {
  .business-table {
    white-space: nowrap;
  }

  .clearfix .float-start {
    float: inherit !important;
  }

  .clearfix .float-end {
    float: left !important;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .business-table {
    white-space: nowrap;
  }
}

/* End Add Business Page */

/* About Us Page */
.about-text h1 {
  color: black;
  font-size: 44px;
  font-weight: 700;
}

.about-text p {
  color: #5b5b5b;
  font-size: 18px;
}

.orange-line {
  position: relative;
  border-radius: 12px;
}

.orange-line::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/orange-line.png");
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 12px;
  background-repeat: no-repeat;
}

.orange-box {
  position: relative;
  border-radius: 12px;
}

.orange-box::after {
  content: "";
  position: absolute;
  background: url("../src/assets/images/orange-box.png");
  mix-blend-mode: multiply;
  width: 254px;
  height: 289px;
  z-index: 1;
  border-radius: 12px;
  background-repeat: no-repeat;
  bottom: -25px;
  left: 56%;
}

.btn-work {
  background: #ff842a;
  border-radius: 11px;
  color: white;
  font-size: 16px;
  padding: 12px 40px;
  font-weight: 700;
}

.about-text h2 {
  color: #ff842a;
  font-size: 36px;
  font-weight: 700;
}

.about-text h6 {
  font-size: 24px;
  font-weight: 700;
  color: black;
}

.text-lorem {
  font-size: 16px !important;
  font-weight: 500;
  color: #5b5b5b;
}

@media (max-width: 767px) {
  .about-text h1 {
    color: black;
    font-size: 30px;
    font-weight: 700;
    margin-top: 10px;
  }

  .mobile-reverse {
    flex-direction: column-reverse;
  }

  .orange-box::after {
    width: 30%;
    height: 30%;
    bottom: -15px;
    left: 75%;
  }

  .about-text p {
    color: #5b5b5b;
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .about-text h1 {
    color: black;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
  }

  .about-text p {
    color: #5b5b5b;
    font-size: 15px;
    margin-bottom: 0px;
  }

  .orange-box::after {
    width: 55%;
    height: 50%;
    bottom: -60px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .about-text h1 {
    color: black;
    font-size: 30px;
    font-weight: 700;
    margin-top: 10px;
  }

  .about-text p {
    color: #5b5b5b;
    font-size: 15px;
    margin-bottom: 0px;
  }

  .orange-box::after {
    width: 55%;
    height: 50%;
    bottom: -60px;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .about-text h1 {
    color: black;
    font-size: 30px;
    font-weight: 700;
    margin-top: 10px;
  }

  .about-text p {
    color: #5b5b5b;
    font-size: 15px;
    margin-bottom: 0px;
  }

  .about-text h6 {
    font-size: 20px;
    font-weight: 700;
    color: black;
  }

  .orange-box::after {
    width: 55%;
    height: 50%;
    bottom: -60px;
  }
}

/* End About Us Page */

/* Terms & Conditions Page */
.terms-text h1 {
  color: black;
  font-size: 40px;
  font-weight: 700;
}

.terms-text h3 {
  color: black;
  font-weight: 700;
}

.terms-text p {
  color: #5b5b5b;
  font-weight: 500;
}
/* End Terms & Conditions Page */

/* Contact Us Page */
.fc-contact .form-control {
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #dddddd;
}

.fc-contact .form-control:focus {
  box-shadow: none;
}

.fc-contact label {
  color: #333f51;
  font-weight: 500;
}

.text-contact {
  color: black;
  font-size: 35px;
  font-weight: 700;
}

.contact-bread .breadcrumb-item a {
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
}

.contact-bread .breadcrumb-item.active {
  color: black !important;
}

.btn-send {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  box-shadow: 0px 23px 24px -10px rgba(255, 123, 57, 0.37);
  border-radius: 11px;
  color: white;
  font-size: 16px;
  padding: 15px 50px;
  font-weight: 700;
}

/* End Contact Page */

/* Global Admin Pages */
@media (max-width: 767px) {
  .desktop-icon {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .mobile-icon {
    display: none;
  }
}

@media (min-width: 1200px) {
  .mobile-icon {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .mobile-icon {
    display: none;
  }

  .show.menu-toggle .deznav .metismenu > li {
    margin-left: 0px;
    margin-right: 0px;
  }

  [data-sidebar-style="full"][data-layout="vertical"]
    .deznav
    .metismenu
    > li
    > a {
    padding: 15px 18px;
    font-size: 15px;
  }

  [data-sidebar-style="full"][data-layout="vertical"]
    .deznav
    .metismenu
    > li
    > a
    > img {
    padding: 0px 8px 0px 0px;
  }
}
/* End Admin Pages */

.tooltips .tooltipstext {
  display: none;
}

.menu-toggle .tooltips {
  position: relative;
  display: inline-block;
}

.menu-toggle .tooltips .tooltipstext {
  display: block;
}

.menu-toggle .tooltips .tooltipstext {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 13px;
  left: 250%;
  margin-left: -60px;
  opacity: 0;
  font-size: 13px !important;
  white-space: nowrap;
  transition: opacity 0.3s;
  box-shadow: 0px 23px 24px -10px rgba(255, 123, 57, 0.37);
}

/*.menu-toggle .tooltips .tooltipstext::after {
 content: "";
 position: absolute;
 top: 23%;
 left: -12%;;
 border-width: 8px;
 border-style: solid;
 transform: rotate(90deg);
 border-color: black transparent transparent transparent;
}
*/
.menu-toggle .tooltips:hover .tooltipstext {
  visibility: visible;
  opacity: 1;
}
/*.menu-toggle .my-overflow{
 overflow: inherit !important;
}*/

@media (min-width: 768px) and (max-width: 1200px) {
  .tooltips {
    position: relative;
    display: inline-block;
  }

  .tooltips .tooltipstext {
    display: block;
  }

  .tooltips .tooltipstext {
    visibility: hidden;
    width: 100px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: -1;
    top: 13px;
    left: 250%;
    margin-left: -60px;
    opacity: 0;
    font-size: 13px !important;
    white-space: nowrap;
    transition: opacity 0.3s;
    box-shadow: 0px 23px 24px -10px rgba(255, 123, 57, 0.37);
  }

  /* .tooltips .tooltipstext::after {
 content: "";
 position: absolute;
 top: 23%;
 left: -12%;;
 border-width: 8px;
 border-style: solid;
 transform: rotate(90deg);
 border-color: black transparent transparent transparent;
}*/

  .tooltips:hover .tooltipstext {
    visibility: visible;
    opacity: 1;
  }
  .my-overflow {
    overflow: inherit !important;
  }
}

@media (min-width: 768px) {
  [data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
    position: fixed;
  }
}

[data-sidebar-position="fixed"][data-layout="vertical"]
  .deznav
  ::-webkit-scrollbar {
  display: none;
}

@media (min-width: 900px) and (max-width: 1000px) {
  [data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
    position: absolute;
  }

  [data-sidebar-position="fixed"][data-layout="vertical"]
    .deznav
    ::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 780px) and (max-width: 810px) {
  [data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
    position: absolute;
  }

  [data-sidebar-position="fixed"][data-layout="vertical"]
    .deznav
    ::-webkit-scrollbar {
    display: none;
  }
}

/* // Business Panel  */

.subscription-bg {
  background: url("../src/assets/images/linear-bg.png");
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.subscription-card {
  background: rgb(255 183 119);
  border-radius: 25px;
  border: none;
  color: black;
}
.subscription-card h6 {
  float: right;
  color: white;
  font-weight: bold;
  font-size: 11px;
  background: rgba(255, 129, 38, 1);
  border-radius: 13.5px;
  padding: 5px 10px;
}
.subscription-card .clear {
  clear: both;
}
.subscription-card h2 {
  font-weight: bold;
  color: black;
  font-size: 40px;
}
.subscription-card .small-month {
  font-size: 20px;
  font-weight: 500;
}
.subscription-card .check-bg {
  background-color: #aaffdc4a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  display: inline-block;
}
.subscription-card .check-bg i {
  font-size: 14px;
  color: #00b168;
}
.subscription-card .btn-plan {
  background: #ff6108;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}

.subscription-card .btn-plan:hover {
  background: white;
  border: 1px solid #ff6108;
  color: #ff6108;
}

/* Setting Page Business Panel */

.bg-white-setting {
  background: white;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
}
.e-pro-txt {
  font-weight: 700;
  color: black;
  font-size: 24px;
}
.settings-text label {
  color: #333f51;
  font-size: 16px;
  font-weight: 500;
}
.price-section {
  border: 1px solid #dddddd !important;
  border-radius: 15px;
}
.price-section .digit {
  color: black;
  font-weight: 700;
  font-size: 40px;
}
.price-section .month {
  color: black;
  font-size: 17px;
  font-weight: 500;
}
.price-section h6 {
  color: black;
  font-size: 28px;
  font-weight: 500;
}
.price-section p {
  color: #888888;
  font-weight: 500;
}
.border-left-price {
  border-left: 1px solid #e6e6e6;
}
.price.list-group li {
  font-weight: 500;
  font-size: 16px;
  color: black;
  padding: 20px 0px;
}
.price .form-check-input:checked {
  background-color: #ff8126;
  border-color: #ff8126;
  width: 45px;
  height: 26px;
}
.price .form-switch .form-check-input:focus {
  box-shadow: none;
}
.price .form-switch .form-check-input {
  width: 45px;
  height: 26px;
}
.price .form-check-input:focus {
  border-color: none;
}
.price .form-check-input {
  background-color: #cecece;
  border: 1px solid #cecece;
  background-position: left center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}
.price .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

/* Reports Page Business Panel */

.report-text h2 {
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.report-text p {
  color: #71717a;
  font-size: 14px;
  font-weight: 400;
}

.report-table tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 700;
  color: #111827;
  border: none;
}

.report-table > tbody > tr:nth-of-type(odd) > * {
  background: rgba(255, 255, 252, 1) !important;
  opacity: 1 !important;
}

.report-table > tbody > tr:nth-of-type(even) > * {
  background: #f9fafb;
}

.report-text tr th {
  color: #6b7280;
  font-size: 14px;
  background: #f9fafb;
  padding-top: 15px;
  padding-bottom: 15px;
}

main input.tog {
  opacity: 0;
  position: absolute;
}
main input.tog + label {
  height: 30px;
  width: 50px;
  border-radius: 20px;
  transition: 0.25s ease;
  display: inline-block;
  position: relative;
}
main input.tog + label span {
  position: absolute;
  bottom: 5px;
  left: 50px;
  white-space: nowrap;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  color: #111827;
  margin-left: 1rem !important;
}
main input.tog + label span:before {
  content: "Block";
}
main input.tog + label:before {
  content: "";
  position: absolute;
  display: block;
  height: 26px;
  width: 45px;
  top: 0;
  left: 0;
  border-radius: 20px;
  background: #cecece;
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
}
main input.tog + label:after {
  content: "";
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  bottom: 8px;
  left: 4px;
  border-radius: 60px;
  background: #fff;
  transition: 0.35s;
}
main input.tog:checked + label span:before {
  content: "Unblock";
}
main input.tog:checked + label:before {
  background: #ff8126;
}
main input.tog:checked + label:after {
  left: 23px;
  top: 4px;
}

@media (max-width: 767px) {
  .report-table {
    white-space: nowrap;
  }
  main input.tog + label span {
    position: absolute;
    bottom: -19px;
    left: -3px;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: #111827;
    text-align: center;
    margin-left: 0px !important;
  }
  main input.tog {
    opacity: 0;
    display: none;
    position: inherit;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  main input.tog + label span {
    position: absolute;
    bottom: -19px;
    left: -3px;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: #111827;
    text-align: center;
    margin-left: 0px !important;
  }
  main input.tog {
    opacity: 0;
    display: none;
    position: inherit;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  main input.tog + label span {
    position: absolute;
    bottom: -19px;
    left: -3px;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: #111827;
    text-align: center;
    margin-left: 0px !important;
  }
  main input.tog {
    opacity: 0;
    display: none;
    position: inherit;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  main input.tog + label span {
    position: absolute;
    bottom: -19px;
    left: -3px;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: #111827;
    text-align: center;
    margin-left: 0px !important;
  }
  main input.tog {
    opacity: 0;
    display: none;
    position: inherit;
  }
}

/* My Users Page Business Panel */

.myusers-text h2 {
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.myusers-text p {
  color: #71717a;
  font-size: 14px;
  font-weight: 400;
}

.myusers-table tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 700;
  color: #111827;
  border: none;
}

.myusers-table > tbody > tr:nth-of-type(odd) > * {
  background: rgba(255, 255, 252, 1) !important;
  opacity: 1 !important;
}

.myusers-table > tbody > tr:nth-of-type(even) > * {
  background: #f9fafb;
}

.myusers-text tr th {
  color: #6b7280;
  font-size: 14px;
  background: #f9fafb;
  padding-top: 15px;
  padding-bottom: 15px;
}

.myusers-table .form-check-input:checked {
  background-color: #ff8126;
  border-color: #ff8126;
  width: 45px;
  height: 26px;
}
.myusers-table .form-switch .form-check-input:focus {
  box-shadow: none;
}
.myusers-table .form-switch .form-check-input {
  width: 45px;
  height: 26px;
}
.myusers-table .form-check-input:focus {
  border-color: none;
}
.myusers-table .form-check-input {
  background-color: #cecece;
  border: 1px solid #cecece;
  background-position: left center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}
.myusers-table .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
}

/* Home Business */
.business-home {
  background: #ffffff;
  box-shadow: 0px 10px 13px rgba(17, 38, 146, 0.05);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}
.business-home p {
  color: #8a92a6;
  font-weight: 400;
  font-size: 18px;
}

.business-home h2 {
  color: #000;
  font-weight: 700;
  font-size: 40px;
}

.home-subscription-card {
  background: white;
  border-radius: 8px;
  border: none;
  color: black;
}
.home-subscription-card h6 {
  float: right;
  color: white;
  font-weight: bold;
  font-size: 11px;
  background: rgba(255, 129, 38, 1);
  border-radius: 13.5px;
  padding: 5px 10px;
}
.home-subscription-card .clear {
  clear: both;
}
.home-subscription-card h2 {
  font-weight: bold;
  color: black;
  font-size: 40px;
}
.home-subscription-card .small-month {
  font-size: 20px;
  font-weight: 500;
}
.home-subscription-card .check-bg {
  background-color: #bbbbbb4a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  display: inline-block;
}
.home-subscription-card .check-bg i {
  font-size: 14px;
  color: #000;
}
.home-subscription-card .btn-plan {
  background: #ff6108;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
}

.home-subscription-card .btn-plan:hover {
  background: white;
  border: 1px solid #ff6108;
  color: #ff6108;
}
.business-home .clearfix .float-end {
  padding-top: 12px;
}

@media (max-width: 767px) {
  .business-home .clearfix .float-end {
    float: right !important;
  }
  .business-home .clearfix .float-start {
    float: left !important;
  }
  .business-home .clearfix .float-end {
    padding-top: 5px;
  }
  .business-home h2 {
    color: #000;
    font-weight: 700;
    font-size: 30px;
  }
  .business-home p {
    color: #8a92a6;
    font-weight: 400;
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .business-home h2 {
    color: #000;
    font-weight: 700;
    font-size: 25px;
  }
  .business-home p {
    color: #8a92a6;
    font-weight: 400;
    font-size: 14px;
  }
  .business-home .clearfix .float-end {
    padding-top: 10px;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .business-home h2 {
    color: #000;
    font-weight: 700;
    font-size: 25px;
  }
  .business-home p {
    color: #8a92a6;
    font-weight: 400;
    font-size: 15px;
  }
  .business-home .clearfix .float-end {
    padding-top: 5px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .business-home h2 {
    color: #000;
    font-weight: 700;
    font-size: 30px;
  }
  .business-home p {
    color: #8a92a6;
    font-weight: 400;
    font-size: 15px;
  }
  .business-home .clearfix .float-end {
    padding-top: 5px;
  }
  .p-1200 ul {
    white-space: nowrap;
    /* padding: 2.5rem !important; */
  }
}

/* Media Queries */

@media (min-width: 1400px) and (max-width: 1500px) {
  .bg.bg-media {
    height: 100%;
  }
  .head-black {
    color: black;
    font-size: 25px;
    font-weight: 700;
  }
  .bg-img {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 1210px) and (max-width: 1300px) {
  .px-media-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .bg.bg-media {
    height: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .bg.bg-media {
    height: 100%;
  }
  .bg-img {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .bg {
    height: 100%;
  }
  .head-black {
    color: black;
    font-size: 25px;
    font-weight: 700;
  }
  .bg-img {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .mobile-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .bg {
    height: 50vh;
    object-fit: cover;
  }
  .px-media-0 {
    padding-top: 20px;
  }
  .subscription-card {
    margin-bottom: 20px;
  }
}

/* .ElementsApp, */
.payment-form .ElementsApp {
  border: 1px solid black !important;
}

/* Loader */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
