// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.App-header {
  .wrapper {
    padding-left: 0 !important;
  }
}

.searchbar {
  width: 18rem;
  padding: 0.3rem;
  border: none;
  border-radius: 100rem;
  padding-top: 0.5rem;
  margin: 0.5rem;
  box-shadow: none;
  outline: none;
  box-shadow: 0 3px 10px rgb(0 0 0 / 50%);
}

.forgot {
  justify-content: end;
}

.cards {
  background-color: #111118;
  width: 275px;
  text-align: center;
  color: #000000;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.profile-image {
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  width: 40px;
  height: 40px;
}

.postImage {
  border-radius: 50%;
  width: 3rem;
}

.cards {
  .card-icons {
    height: 80px;
  }
}

.modal {
  margin: 0.6rem;
  padding: 0.5rem 1.5rem;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.7rem;
}

.modal-btn {
  margin: 0.6rem;
  padding: 0.5rem 1.5rem;
  background-color: #111118;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.7rem;
}

.login-text {
  color: linear-gradient(to right, #fa5e51 0%, #fda085 45%, #f6d365 100%);
}

.update-btn {
  height: 40px;
  width: 80px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0;
}

.text-center {
  .admin-image {
    width: 150px;
    height: 100px;
    // margin-bottom: 1rem;
  }
}

.admin-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.forgot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
}

.sidebar-nav .nav-link.active {
  background: linear-gradient(271.86deg, #ff8126 0.13%, #fda24e 97.84%);
  border-radius: 10px;
}

.new-tabs-justify.nav-pills .nav-link.active {
  background: #ff6108;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  color: white;
  padding: 12px 25px;
}

.new-tabs-justify.nav-pills .nav-link {
  // background: ;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  color: #ff6108;
  padding: 12px 25px;
}

.subscription-bg {
  height: 100%;
}

.ElementsApp {
  border: 1px solid black !important;
}

/* Loader */

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
